import { useContext, useLayoutEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { CustomizedAppBar } from "../../../styles/common/navbar";
import { BackIcon, BeeLogo } from "../../../img/svg";
import LoginForm from "../../dynamicHeader/login";
import { getSession, removeSession } from "../../../services/session";
import SignupModal from "../../SignupModal";
import ComingSoonModel from "../comingSoonModel";
import MenuIcon from "@mui/icons-material/Menu";
import { btnOrangeFive, btnOrangeFour } from "../../../styles/common/button";
import UserAvatar from "../navbar/userAvatar";
import { ChangeuserNamePage } from "../../pages/Account/ChangeuserNames";
import { ChangePasswordPage } from "../../pages/Account/ChangePasswords";
import { ChangeuserNotificationsPage } from "../../pages/Account/ChangeNotifications";
import RevokeSupport from "../../pages/Account/RevokeSupport";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../context/language";

const Navbar = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [changeUserNameModal, setChangeUserNameModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changeNotificationModal, setChangeNotificationModal] = useState(false);
  const [revokeSupportDialogVisible, setRevokeSupportDialogVisible] =
    useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [showComingSoonModel, setShowComingSoonModel] = useState(false);
  const [subStack, setSubStack] = useState(false);
  const [session, setSession] = useState(getSession());
  const showMenu = useMediaQuery("(max-width:900px)");
  const showButtons = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const history = useHistory();
  const { language, setLanguage } = useContext(LanguageContext);

  useLayoutEffect(() => {
    if (localStorage.getItem("language") !== "") {
      setLanguage(localStorage.getItem("language"));
    }
  }, []);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.id);
    localStorage.setItem("language", event.target.id);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const toggleLoginModalVisibility = () => {
    setShowLoginModal((prevState) => !prevState);
    handleCloseNavMenu();
  };

  const toggleSignupModalVisibility = () => {
    setShowSignupModal((prevState) => !prevState);
  };

  const sessionUpdated = () => {
    const session = getSession();
    if (!session) setSession(session);
    else setSession(getSession());
  };

  const logout = () => {
    removeSession();
    setTimeout(() => sessionUpdated(), 0);
    handleCloseNavMenu();
  };

  const sessionUpdatedModel = () => {
    setShowLoginModal(false);
    setTimeout(() => sessionUpdated(), 0);
  };

  const handleComingSoonModal = (event) => {
    event.target.id === "substack" ? setSubStack(true) : setSubStack(false);
    setShowComingSoonModel(!showComingSoonModel);
  };

  const handleChangeUserNameModal = () => {
    setChangeUserNameModal(!changeUserNameModal);
  };

  const handleChangePasswordModal = () => {
    setChangePasswordModal(!changePasswordModal);
  };

  const handleChangeNotificationModal = () => {
    setChangeNotificationModal(!changeNotificationModal);
  };

  const handleRevokeSupportDialogVisible = () => {
    setRevokeSupportDialogVisible(!revokeSupportDialogVisible);
  };

  const handleSupporterButton = () => {
    history.push("#supporter");
    handleCloseNavMenu();
  };

  console.log({ history });

  return (
    <>
      <CustomizedAppBar position="static">
        <Toolbar>
          <Button
            onClick={() => {
              // if (history.length === 1) {
              //   window.open("https://www.electionscience.org", "_self")
              // }else {
              //   history.push("/")
              // }
              window.open("https://www.commonsenseforunitingamerica.org/", "_self")

            }}
            sx={{ textDecoration: "none !important", cursor: "pointer" }}
            underline="none"
          >

            <Stack direction="row" gap={2} alignItems="center" sx={{
              "& svg": {
                marginTop: "-12px"
              }
              
            }}>
              <BeeLogo />
              <Typography color={"black"} variant="subtitle2" display={{ xs: "none", lg: "block", fontWeight: "bold" }}>
                {t("logo_text1")}
              </Typography>
            </Stack>
          </Button>
          <Stack direction="row" gap={0.2} alignItems="center">
            <Typography
              variant="subtitle2"
              display={{ xs: "flex", lg: "block", fontWeight: "bold" }}
            >
              Supporter Portal
            </Typography>
          </Stack>
          {!showButtons && (
            <>
              <Stack
                direction="row"
                gap={{ sm: "15px", md: "30px" }}
                alignItems="center"
              >
                <Stack direction="row" gap={2} alignItems="center">
                  <Button
                    role="a"
                    href="#supporter"
                    underline="none"
                    sx={{
                      ...btnOrangeFive,
                      color: "white",
                    }}
                  >
                    {t("becomeSupporter")}
                  </Button>
                  {!session && !localStorage.getItem("session") && (
                    <Button
                      id="substack"
                      variant="contained"
                      onClick={toggleLoginModalVisibility}
                      sx={btnOrangeFour}
                    >
                      {t("login")}
                    </Button>
                  )}
                </Stack>

                {/* <Stack
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={() => history.push("/resources")}>
                  <Tooltip
                    title="click for more resources and information"
                    arrow
                  >
                    <Typography variant="body2"> {t("resources")}</Typography>
                  </Tooltip>
                </MenuItem>

                <MenuItem
                  onClick={() => openInNewTab(PDF_JOIN_THE_JOURNEY_URL)}
                >
                  <Typography variant="body2">{t("joinTheJourney")}</Typography>
                </MenuItem>

                <MenuItem
                  variant="contained"
                  onClick={() => handleComingSoonModal()}
                >
                  {t("freeGifts")}
                </MenuItem>
                <MenuItem
                  variant="contained"
                  onClick={() => toggleSignupModalVisibility()}
                >
                  {t("login")}
                </MenuItem>

                {!session && !localStorage.getItem("session") && (
                  <MenuItem onClick={toggleLoginModalVisibility}>
                    <Typography variant="body2" sx={{ cursor: "pointer" }}>
                      {t("login")}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Stack> */}

                {session && localStorage.getItem("session") && (
                  <UserAvatar
                    handleChangeUserNameModal={handleChangeUserNameModal}
                    handleChangePasswordModal={handleChangePasswordModal}
                    handleChangeNotificationModal={
                      handleChangeNotificationModal
                    }
                    handleRevokeSupportDialogVisible={
                      handleRevokeSupportDialogVisible
                    }
                    logout={logout}
                  />
                )}
              </Stack>
            </>
          )}
          <Stack
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => handleSupporterButton()}>
                <Typography variant="body2"> {t("becomeSupporter")}</Typography>
              </MenuItem>

              {!session && !localStorage.getItem("session") && (
                <MenuItem onClick={toggleLoginModalVisibility}>
                  <Typography variant="body2" sx={{ cursor: "pointer" }}>
                    {t("login")}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Toolbar>
      </CustomizedAppBar>

      {showLoginModal && (
        <LoginForm
          isOpen={showLoginModal}
          toggleLoginVisibility={(e) => toggleLoginModalVisibility()}
          sessionUpdated={sessionUpdatedModel}
        />
      )}

      {changeUserNameModal && (
        <ChangeuserNamePage
          isVisible={changeUserNameModal}
          reverseIsVisible={(e) => handleChangeUserNameModal()}
          sessionUpdated={sessionUpdatedModel}
          session={session}
        />
      )}

      {changePasswordModal && (
        <ChangePasswordPage
          isVisible={changePasswordModal}
          reverseIsVisible={handleChangePasswordModal}
          session={session}
          sessionUpdated={sessionUpdatedModel}
        />
      )}

      {changeNotificationModal && (
        <ChangeuserNotificationsPage
          isVisible={changeNotificationModal}
          reverseIsVisible={handleChangeNotificationModal}
          session={session}
          sessionUpdated={sessionUpdatedModel}
        />
      )}

      {showSignupModal && (
        <SignupModal
          isOpen={showSignupModal}
          toggleVisibility={(e) => toggleSignupModalVisibility()}
        />
      )}

      {revokeSupportDialogVisible && (
        <RevokeSupport
          isVisible={revokeSupportDialogVisible}
          reverseIsVisible={handleRevokeSupportDialogVisible}
          offsetTop={() => { }}
          session={session}
          sessionUpdated={sessionUpdatedModel}
          goToPage={() => { }}
        />
      )}

      {/* {showComingSoonModel && (
        <ComingSoonModel
          isOpen={showComingSoonModel}
          toggleModel={() => handleComingSoonModal()}
        />
      )} */}

      {showComingSoonModel && (
        <ComingSoonModel
          substack={subStack}
          isOpen={showComingSoonModel}
          toggleModel={(e) => handleComingSoonModal(e)}
        />
      )}
    </>
  );
};

export default Navbar;
